import React from "react";
import loginImg from "../../images/login-back.png";
import logo from "../../images/ehcc_logo_colour 1.png";
import { Link } from "react-router-dom";

import { Flex, Box, Image, Heading, Text } from "@chakra-ui/react";
import ImageComponent from "./Image";

export default function AuthComponent({
  children,
  text,
  path,
  linkText,
  heading,
  blue,
}) {
  return (
    <Flex w="100%" h="100vh" justify="space-between" alignItems="center">
      <Box display={{ base: "none", md: "none", lg: "block" }} w="50%">
        <ImageComponent height="100vh" src={loginImg} alt="login" />
      </Box>
      <Flex
        w={{ base: "100%", md: "100%", lg: "50%" }}
        flexDirection="column"
        gap="16px"
        p={{ base: "1rem", md: "1rem", lg: "0px" }}
      >
        <Box w="8rem" mx="auto">
          <Image
            boxSize="100%"
            objectFit="cover"
            h="6.25rem"
            src={logo}
            alt="login"
          />
        </Box>
        <Heading
          as="h1"
          fontSize="1.5rem"
          fontWeight="bold"
          textAlign="center"
          color="#3F434A"
        >
          {heading}
        </Heading>
        {children}
        <Box mt="6rem">
          <Text
            textAlign="center"
            fontSize="15px"
            fontWeight="light"
            color="#8A9099"
          >
            {text} {""}
            <Link
              to={`/${path}`}
              className={`${blue ? "text-blue-500" : "text-gray-600"}`}
            >
              {linkText}
            </Link>
          </Text>
        </Box>
      </Flex>
    </Flex>
  );
}
