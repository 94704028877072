import { Flex, Box } from "@chakra-ui/react";
import React, { Fragment, useEffect, useState } from "react";
import DoubleInputState from "../ui/DoubleInputState";
import ReusableTextarea from "../ui/ReusableTextarea";

import Comments from "./Comments";
import { getUsers } from "../../util/http";
import ReusableMisedInput from "../ui/ReusableMisedInput";
import { useForm } from "react-hook-form";

export default function Detail({ report }) {
  const token = localStorage.getItem("token");
  const id = localStorage.getItem("memberID");
  const [user, setUser] = useState("");

  let thoseSickReport,
    obtainedPermissionReport,
    notObtainedPermissionReport,
    reasonsReport;

  if (!report) {
    thoseSickReport = [];
    obtainedPermissionReport = [];
    notObtainedPermissionReport = [];
    reasonsReport = [];
  } else {
    thoseSickReport = JSON.parse(report?.those_sick);
    obtainedPermissionReport = JSON.parse(report?.obtained_permission);
    notObtainedPermissionReport = JSON.parse(report?.not_obtain_permission);
    reasonsReport =
      report?.reasons?.length !== 0 ? JSON.parse(report?.reasons) : [];
  }

  const {
    control,
  } = useForm();

  useEffect(() => {
    const role = localStorage.getItem("role");


    async function fetchData() {
      try {
        const response = await getUsers(token, id);
        setUser(response?.user[0]);
      } catch (error) {
        // console.log(error);
      }
    }

    if (role?.toLowerCase() !== "member") {

      fetchData();
    }
  }, []);

  return (
    <Fragment>
      <Flex w="100%" direction="column" gap="2rem">
        <DoubleInputState
          label1="Date"
          label2="SERVICE"
          placeholder=""
          value1={report?.report_date || ""}
          value2={report?.service || ""}
          control={control}
          name1={"report_date"}
          name2={"service"}
        />
        <DoubleInputState
          label1="No. of Department Members"
          label2="NO. MEMBERS IN SERVICE"
          placeholder="e.g 3"
          value1={report?.department_members || ""}
          value2={report?.members_in_service || ""}
          control={control}
          name1={"department_members"}
          name2={"members_in_service"}
        />

        {false && (
          <Fragment>
            {report?.reasons?.length === 0 ? (
              <DoubleInputState
                label1="Reason for being Absent"
                label2=""
                placeholder="Full Name"
                placeholder2="Reason"
                control={control}
                name1={"full_name"}
                name2={"reason"}
              // value1={el.full_name}
              // value2={el.reason}
              />
            ) : (
              <>
                {report?.reasons?.map((el) => (
                  <DoubleInputState
                    label1="Reason for being Absent"
                    label2=""
                    placeholder="Full Name"
                    placeholder2="Reason"
                    value1={el.full_name}
                    value2={el.reason}
                    control={control}
                    name1={"full_name"}
                    name2={"reason"}
                  />
                ))}
              </>
            )}
          </Fragment>
        )}

        <Fragment>
          {obtainedPermissionReport?.length === 0 ? (
            <DoubleInputState
              label1="NAME AND NUMBER OF THOSE WHO OBTAINED PERMISSION"
              label2=""
              placeholder="Full Name"
              placeholder2="Phone number"
              control={control}
              name1={"full_name"}
              name2={"phone_number"}
            />
          ) : (
            <>
              {obtainedPermissionReport?.map((el) => (
                <DoubleInputState
                  label1="NAME AND NUMBER OF THOSE WHO OBTAINED PERMISSION"
                  label2=""
                  placeholder="Full Name"
                  placeholder2="Phone number"
                  value1={el.full_name}
                  value2={el.phone_number}
                  control={control}
                  name1={"full_name"}
                  name2={"phone_number"}
                />
              ))}
            </>
          )}
        </Fragment>

        <Fragment>
          {notObtainedPermissionReport?.length === 0 ? (
            <DoubleInputState
              label1="NAME AND NUMBER OF THOSE WHO DID NOT OBTAIN PERMISSION"
              label2=""
              placeholder="Full Name"
              placeholder2="Phone number"
              control={control}
              name1={"full_name"}
              name2={"phone_number"}
            />
          ) : (
            <>
              {notObtainedPermissionReport?.map((el) => (
                <DoubleInputState
                  label1="NAME AND NUMBER OF THOSE WHO DID NOT OBTAIN PERMISSION"
                  label2=""
                  placeholder="Full Name"
                  placeholder2="Phone number"
                  value1={el.full_name}
                  value2={el.phone_number}
                  control={control}
                  name1={"full_name"}
                  name2={"phone_number"}
                />
              ))}
            </>
          )}
        </Fragment>

        <Fragment>
          {thoseSickReport?.length === 0 ? (
            <DoubleInputState
              label1="Those Sick, Travelled etc"
              label2=""
              placeholder="Full Name"
              placeholder2="Phone Number"
              control={control}
              name1={"full_name"}
              name2={"phone_number"}
            />
          ) : (
            <>
              {thoseSickReport?.map((el) => (
                <DoubleInputState
                  label1="Those Sick, Travelled etc"
                  label2=""
                  placeholder="Full Name"
                  placeholder2="Phone Number"
                  value1={el.full_name}
                  value2={el.phone_number}
                  control={control}
                  name1={"full_name"}
                  name2={"phone_number"}
                />
              ))}
            </>
          )}
        </Fragment>

        <Fragment>
          {reasonsReport?.length === 0 ? (
            <ReusableMisedInput
              label1="REASONS FOR BEING ABSENT"
              label2=""
              placeholder="Full Name"
              placeholder2="Reason"
              control={control}
              name1={"full_name"}
              name2={"reason"}
            />
          ) : (
            <>
              {reasonsReport?.map((el) => (
                <ReusableMisedInput
                  label1="REASONS FOR BEING ABSENT"
                  label2=""
                  placeholder="Full Name"
                  placeholder2="Reason"
                  value1={el.full_name}
                  value2={el.reason}
                  control={control}
                  name1={"full_name"}
                  name2={"reason"}
                />
              ))}
            </>
          )}
        </Fragment>

        <ReusableTextarea
          label="Points of Excellence"
          value={report?.excellence || ""}
          control={control}
          name={"excellence"}
        />

        <ReusableTextarea
          value={report?.need_improvement || ""}
          label="Needs for Improvement (NFIs)"
          bg="#E8E9EB"
          control={control}
          name={"need_improvement"}
        />

        <ReusableTextarea
          label="Any other concern or observations"
          value={report?.observation || ""}
          control={control}
          name={"observation"}
        />

        <ReusableTextarea
          label="HOD’s remarks"
          value={report?.hod_remark || ""}
          control={control}
          name={"hod_remark"}
        />

        <ReusableTextarea
          label="Assitant HOD’s remarks"
          value={report?.assistant_remark || ""}
          name={"assistant_remark"}
          control={control}


        />
      </Flex>
      {report?.status !== "pending" && <Comments user={user} report={report} />}
    </Fragment>
  );
}
