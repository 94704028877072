import React from "react";
import ReusableSubmissionHeader from "../../components/ui/ReusableSubmissionHeader";
import { adminReports , getPastorDashboard } from "../../util/http";

export default function DirectorateSubmissionHeader({ setReport }) {
  const token = localStorage.getItem("token");
  const role = localStorage.getItem("role");

  async function filterPending() {
    let response;
    if (role.toLowerCase() === "directorate") {
      response = await adminReports(token);
    } else if (role.toLowerCase() === "pastor") {
      response = await getPastorDashboard(token);
    }

    const filteredItem = response?.reports?.filter(
      (el) => el.status?.toLowerCase() === "pending"
    );
    setReport(filteredItem || []);
  }

  async function filterApproved() {
    let response;
    if (role.toLowerCase() === "directorate") {
      response = await adminReports(token);
    } else if (role.toLowerCase() === "pastor") {
      response = await getPastorDashboard(token);
    }

    const filteredItem = response?.reports?.filter(
      (el) => el.status?.toLowerCase() === "approved"
    );
    setReport(filteredItem || []);
  }

  async function filterQueried() {
 let response;
    if (role.toLowerCase() === "directorate") {
      response = await adminReports(token);
    } else if (role.toLowerCase() === "pastor") {
      response = await getPastorDashboard(token);
    }

    const filteredItem = response?.reports?.filter(
      (el) => el.status?.toLowerCase() === "queried"
    );

    setReport(filteredItem || []);
  }

  async function filterAll() {

    let response;
    if (role.toLowerCase() === "directorate") {
      response = await adminReports(token);
    } else if (role.toLowerCase() === "pastor") {
      response = await getPastorDashboard(token);
    }
    setReport(response?.reports || []);
  }



  return (
    <ReusableSubmissionHeader
      filterApproved={filterApproved}
      filterPending={filterPending}
      filterQueried={filterQueried}
      filterAll={filterAll}
    />
  );
}
