import React from "react";
import {
  Flex,
  Spacer,
  Heading,
  Box,
  Menu,
  MenuButton,
  MenuList,
  Button,
  MenuItem,
} from "@chakra-ui/react";

import { ChevronDownIcon } from "@chakra-ui/icons";

export default function ReusableSubmissionHeader({
  filterApproved,
  filterPending,
  filterQueried,
  filterAll,
}) {
  return (
    <Flex>
      <Heading fontSize="20px" fontWeight="medium" color="#3F434A">
        Latest Submissions
      </Heading>
      <Spacer />

      <Menu>
        <MenuButton
          bg="white"
          border="1px solid #ccc"
          borderRadius="0.75rem"
          as={Button}
          rightIcon={<ChevronDownIcon />}
          _hover={{ bg: "transparent" }}
          _expanded={{ bg: "transparent" }}
        >
          Filter
        </MenuButton>
        <MenuList>
        <MenuItem
            _focus={{ bg: "#fff" }}
            _hover={{ bg: "#fff" }}
            _expanded={{ bg: "#fff" }}
            onClick={filterAll}
          >
           All
          </MenuItem>
          <MenuItem
            _focus={{ bg: "#fff" }}
            _hover={{ bg: "#fff" }}
            _expanded={{ bg: "#fff" }}
            onClick={filterPending}
          >
            Pending
          </MenuItem>
          <MenuItem
            _focus={{ bg: "#fff" }}
            _hover={{ bg: "#fff" }}
            _expanded={{ bg: "#fff" }}
            onClick={filterApproved}
          >
            Approved
          </MenuItem>
          <MenuItem
            _focus={{ bg: "#fff" }}
            _hover={{ bg: "#fff" }}
            _expanded={{ bg: "#fff" }}
            onClick={filterQueried}
          >
            Queried
          </MenuItem>
        </MenuList>
      </Menu>
    </Flex>
  );
}
