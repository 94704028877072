import React, { useEffect, useState } from "react";
import Icon from "../../components/ui/Icon";
import {
  Flex,
  Text,
  Menu,
  MenuButton,
  MenuList,
  Button,
  Avatar,
} from "@chakra-ui/react";
import SubmissionText from "../../components/ui/SubmissionText";
import { useNavigate } from "react-router-dom";
import { BsThreeDotsVertical } from "react-icons/bs";
import { AiOutlineEye } from "react-icons/ai";
import { getUsers } from "../../util/http";
import EditUser from "./EditUser";

export default function UserItem({
  item,
  setUserId,

  user,
}) {
  const navigate = useNavigate();

  return (
    <Flex
      cursor="pointer"
      _hover={{
        opacity: "80%",
        transition: "all",
        transitionDuration: "300",
      }}
      py="2"
      justify="space-between"
      align="center"
      onClick={() => {
        navigate(`/directorate-user/${item.id}`);
      }}
    >
      <Flex w="20%" align="center" gap="1rem">
        <Avatar size="xs" name={item.first_name} src={item.picture} />
        <Text
          style={{ textTransform: "capitalize" }}
          fontSize={{ base: "10px", md: "10px", lg: "16px" }}
          fontWeight="medium"
          color="#3F434A"
        >
          {item.first_name} {item.last_name}
        </Text>
      </Flex>
      <SubmissionText
        display={{ base: "none", md: "none", lg: "block" }}
        capitalize="uppercase"
        width="20%"
        text={item.member_id.toUpperCase()}
        color="#8A9099"
      />
      <SubmissionText text={item.department} width="20%" />
      <SubmissionText text={item.designation} width="20%" />

      <Flex align="center" justify="end" cursor="pointer" w="20%" gap="1rem">
        <SubmissionText
          display={{ base: "none", md: "none", lg: "block" }}
          text={item?.phone_number?.slice(0, 12)}
          fw="normal"
        />
        <Menu>
          <MenuButton
            _focus={{ bg: "#fff" }}
            _expanded={{ bg: "#fff" }}
            bg="transparent"
            colorScheme="purple"
            as={Button}
            _hover={{
              backgroundColor: "white",
            }}
          >
            <Icon icon={<BsThreeDotsVertical />} />
          </MenuButton>
          <MenuList
            display="flex"
            justify="center"
            align="center"
            cursor="pointer"
            gap="1rem"
            px="1rem"
            _hover={{
              backgroundColor: "white",
              opacity: "100%",
              color: "brand.900",
              fontWeight: "bold",
            }}
            _focus={{ bg: "#fff" }}
            _expanded={{ bg: "#fff" }}
            onClick={() => {
              navigate(`/directorate-user/${item.id}`);
            }}
          >
            <SubmissionText text="View User" fw="bold" />
          </MenuList>
        </Menu>
      </Flex>
    </Flex>
  );
}
