import React from "react";
import DirectorateDashboard from "../directorate/dashboard/DirectorateDashboard";
import { useContext } from "react";
import { AuthContext } from "../util/authContext";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { redirect } from "react-router-dom";
import { Fragment } from "react";
import LoginPage from "./LoginPage";
import IdleWrapper from "../components/auth/IdleWrapper";

export default function DirectorateDashboardPage() {
  const ctx = useContext(AuthContext);

  return (
    <Fragment>
      <IdleWrapper>
      {ctx.isLoggedIn ? <DirectorateDashboard /> : <LoginPage />}
      </IdleWrapper>
    </Fragment>
  );
}
