import { Flex, useToast } from "@chakra-ui/react";
import React, { Fragment, useState, useEffect } from "react";
import DoubleInputState from "../../components/ui/DoubleInputState";
import ReusableComment from "../../components/ui/ReusableComment";
import ReusableTextarea from "../../components/ui/ReusableTextarea";

import { addComment, getUsers } from "../../util/http";
import Comments from "../../components/report-detail/Comments";
import ReportActions from "./ReportActions";
import ReusableMisedInput from "../../components/ui/ReusableMisedInput";
import { useForm } from "react-hook-form";


export default function DirectorateDetail({ report }) {
  const toast = useToast();
  const {
    control
  } = useForm();

  function showToast(title, description, status) {
    toast({
      title: title,
      description: description,
      status,
      duration: 3000,
      isClosable: true,
      position: "top",
    });
  }

  const token = localStorage.getItem("token");
  const id = localStorage.getItem("memberID");
  const [user, setUser] = useState("");
  const [comment, setComment] = useState("");

  let thoseSickReport,
    obtainedPermissionReport,
    notObtainedPermissionReport,
    reasonsReport;

  if (!report) {
    thoseSickReport = [];
    obtainedPermissionReport = [];
    notObtainedPermissionReport = [];
    reasonsReport = [];
  } else {
    thoseSickReport = JSON.parse(report?.those_sick);
    obtainedPermissionReport = JSON.parse(report?.obtained_permission);
    notObtainedPermissionReport = JSON.parse(report?.not_obtain_permission);
    reasonsReport =
      report?.reasons?.length !== 0 ? JSON.parse(report?.reasons) : [];
  }

  async function createCommentHandler() {
    const data = {
      type: user?.user_role,
      report_id: report?.id,
      body: comment,
    };
    if(data?.body?.length < 1){
      showToast(
        "Please enter a comment!",
        "",
        "error"
      );
      return
    }
    else{
      try {
        const response = await addComment(token, data);
        showToast("Report queried", "", "success");
      } catch (error) {
        showToast(
          error?.response?.data.message || "something went wrong",
          "",
          "error"
        );
        // console.log(error);
      }

    }



  }

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await getUsers(token, id);
        setUser(response?.user[0]);
      } catch (error) {
        // console.log(error);
      }
    }
    fetchData();
  }, [id, token]);

  return (
    <Fragment>
      <Flex direction="column" gap="2rem">
        <DoubleInputState
          label1="Date"
          label2="Service"
          placeholder=""
          value1={report?.report_date || ""}
          value2={report?.service || ""}
          name1={"report_date"}
          name2={"service"}
          control={control}

        />
        <DoubleInputState
          label1="No. of Department Members"
          label2="No. of Members in Service"
          placeholder="e.g 3"
          value1={report?.department_members || ""}
          value2={report?.members_in_service || ""}
          name1={"department_members"}
          name2={"members_in_service"}
          control={control}
        />

        <Fragment>
          {obtainedPermissionReport?.length === 0 ? (
            <DoubleInputState
              label1="NAME AND NUMBER OF THOSE WHO OBTAINED PERMISSION"
              label2=""
              placeholder="Full Name"
              placeholder2="Phone number"
              name1={"full_name"}
              name2={"phone_number"}
              control={control}
            />
          ) : (
            <>
              {obtainedPermissionReport?.map((el) => (
                <DoubleInputState
                  label1="NAME AND NUMBER OF THOSE WHO OBTAINED PERMISSION"
                  label2=""
                  placeholder="Full Name"
                  placeholder2="Phone number"
                  value1={el.full_name}
                  value2={el.phone_number}
                  name1={"full_name"}
                  name2={"phone_number"}
                  control={control}
                />
              ))}
            </>
          )}
        </Fragment>

        <Fragment>
          {notObtainedPermissionReport?.length === 0 ? (
            <DoubleInputState
              label1="NAME AND NUMBER OF THOSE WHO DID NOT OBTAIN PERMISSION"
              label2=""
              placeholder="Full Name"
              placeholder2="Phone number"
              name1={"full_name"}
              name2={"phone_number"}
              control={control}
            />
          ) : (
            <>
              {notObtainedPermissionReport?.map((el) => (
                <DoubleInputState
                  label1="NAME AND NUMBER OF THOSE WHO DID NOT OBTAIN PERMISSION"
                  label2=""
                  placeholder="Full Name"
                  placeholder2="Phone number"
                  value1={el.full_name}
                  value2={el.phone_number}
                  name1={"full_name"}
                  name2={"phone_number"}
                  control={control}
                />
              ))}
            </>
          )}
        </Fragment>

        <Fragment>
          {thoseSickReport?.length === 0 ? (
            <DoubleInputState
              label1="Those Sick, Travelled etc"
              label2=""
              placeholder="Full Name"
              placeholder2="Phone Number"
              name1={"full_name"}
              name2={"phone_number"}
              control={control}
            />
          ) : (
            <>
              {thoseSickReport?.map((el) => (
                <DoubleInputState
                  label1="Those Sick, Travelled etc"
                  label2=""
                  placeholder="Full Name"
                  placeholder2="Phone Number"
                  value1={el.full_name}
                  value2={el.phone_number}
                  name1={"full_name"}
                  name2={"phone_number"}
                  control={control}
                />
              ))}
            </>
          )}
        </Fragment>

        <Fragment>
          {reasonsReport?.length === 0 ? (
            <ReusableMisedInput
              label1="REASONS FOR BEING ABSENT"
              label2=""
              placeholder="Full Name"
              placeholder2="Reason"
              name1={"full_name"}
              name2={"reason"}
              control={control}
            />
          ) : (
            <>
              {reasonsReport?.map((el) => (
                <ReusableMisedInput
                  label1="REASONS FOR BEING ABSENT"
                  label2=""
                  placeholder="Full Name"
                  placeholder2="Reason"
                  value1={el.full_name}
                  value2={el.reason}
                  name1={"full_name"}
                  name2={"reason"}
                  control={control}
                />
              ))}
            </>
          )}
        </Fragment>
        <ReusableTextarea
          label="Points of Excellence"
          value={report?.excellence || ""}
          name={"excellence"}
          control={control}
        />

        <ReusableTextarea
          value={report?.need_improvement || ""}
          label="Needs for Improvement (NFIs)"
          bg="#E8E9EB"
          name={"need_improvement"}
          control={control}

        />

        <ReusableTextarea
          label="Any other concern or observations"
          value={report?.observation || ""}
          name={"observation"}
          control={control}
        />

        <ReusableTextarea
          label="Assitant HOD’s remarks"
          value={report?.assistant_remark || ""}
          name={"assistant_remark"}
          control={control}
        />

        <ReusableTextarea
          label="HOD’s remarks"
          value={report?.hod_remark || ""}
          name={"hod_remark"}
          control={control}
        />

        {report?.comments.length < 1 && (
          <Fragment>
            {user?.user_role?.toLowerCase() === "directorate" ? (
              <ReusableTextarea
                value={comment}
                readOnly={true}
                label="Director’s remarks"
                onChange={(e) => setComment(e.target.value)}
                control={control}
                name={"comment"}
                // required = {comment ? false : true}



              />
            ) : (
              <ReusableTextarea
                value={comment}
                readOnly={true}
                label="official remarks - resident pastor"
                onChange={(e) => setComment(e.target.value)}
                control={control}
                name={"comment"}
              />
            )}
          </Fragment>
        )}
      </Flex>
      {report?.status !== "pending" && <Comments report={report} user={user} />}
      <ReportActions
        createCommentHandler={createCommentHandler}
        showToast={showToast}
        report={report}
      />
    </Fragment>
  );
}
