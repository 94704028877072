import React from "react";
import { Flex, FormControl, FormLabel, Input , FormErrorMessage } from "@chakra-ui/react";
import { useController } from "react-hook-form";

export default function DoubleInputState({
  label1,
  value1,
  onChange1,
  placeholder,
  placeholder2,
  label2,
  value2,
  onChange2,
  readOnly,
  name1 ,
  name2 ,
  control,
  rules1,
  rules2,

}) {

  const { field: filed1, formState } = useController({ control, name: name1, defaultValue: ""   , rules: rules1 });
  const { field: filed2, formState: formState2 } = useController({ control, name: name2, defaultValue: ""  , rules: rules2 });
  const  error1 = formState?.errors[name1]?.message ;
  const  error2 = formState2?.errors[name2]?.message ;
  return (
    <Flex align="center" justify="space-between" wrap="wrap" gap="2rem">
      <FormControl w={{ base: "100%", md: "100%", lg: "45%" }} isInvalid={error1}>
        <FormLabel
          fontSize="14px"
          // autoCapitalize="on"
          fontWeight="normal"
          h={{ base: "100%", md: "100%", lg: "20px" }}
          color="#515262"
          style={{ textTransform: "uppercase" }}
        >
          {label1}
        </FormLabel>
        <Input
          size="md"
          variant="outline"
          border="1px solid #ccc"
          focusBorderColor="#481053"
          borderRadius="14px"
          type="text"
          placeholder={placeholder}
          value={value1}
          onChange={(e) => {
            filed1.onChange(e);
            if(onChange1) onChange1(e);

          }}

          style={{ textTransform: "capitalize" }}
          readOnly={readOnly ? false : true}
        />
        <FormErrorMessage>{error1}</FormErrorMessage>
      </FormControl>
      <FormControl w={{ base: "100%", md: "100%", lg: "45%" }} isInvalid={error2}>
        <FormLabel
          autoCapitalize="true"
          fontSize="14px"
          fontWeight="normal"
          h="20px"
          color="#515262"
        >
          {label2}
        </FormLabel>
        <Input
          size="md"
          variant="outline"
          border="1px solid #ccc"
          focusBorderColor="#481053"
          borderRadius="14px"
          type="text"
          placeholder={placeholder2 ? placeholder2 : placeholder}
          value={value2}
          onChange={(e) => {
            filed2.onChange(e);
            onChange2(e);
          }}

          readOnly={readOnly ? false : true}
        />
         <FormErrorMessage>{error2}</FormErrorMessage>
      </FormControl>
    </Flex>
  );
}

// outline-none p-2 rounded-xl w-full border;
