import React from "react";
import { FormControl, FormLabel, Input  , FormErrorMessage} from "@chakra-ui/react";
import { useController } from "react-hook-form";

export default function InputComponent({
  label,
  placeholder,
  type,
  value,
  onChange,
  isDisabled,
  min,
  max,
  required = false,
  name ,
  control,
  defaultValue ,
  rules ,
}) {
  const { field ,  formState } = useController({
    control,
    name,
    defaultValue,
    rules,

  })

  const error = formState?.errors[name]?.message

  return (
    <FormControl
      isRequired={required ? required : false}
      w={{ base: "100%", md: "100%", lg: "100%" }}
      isInvalid={error}

    >
      <FormLabel color="#8A9099" fontSize="14px" fontWeight="normal">
        {label}
      </FormLabel>
      <Input
        {...field}
        size="md"
        placeholder={placeholder}
        type={type ? type : "text"}
        variant="outline"
        borderRadius="0.75rem"
        focusBorderColor="brand.900"
        value={value}
        onChange={(e) => {
          field.onChange(e);
          if(onChange){
            onChange(e)
          }
        }}
        min={min}
        max={max}
        isDisabled={isDisabled ? isDisabled : false}


      />
       <FormErrorMessage>{error}</FormErrorMessage>
    </FormControl>
  );
}
