import React from "react";
import { Flex, FormControl, FormLabel, Input  , FormErrorMessage} from "@chakra-ui/react";
import { useController } from "react-hook-form";

export default function DoubleInput({
  label1,
  value1,
  onChange1,
  placeholder,
  placeholder2,
  label2,
  value2,
  onChange2,
  register1,
  register2,
  type,
  max,
  border,
  readOnly,
  required = false,
  control,
  name1,
  name2,
  rules1,
  rules2,
  type1 ,
  type2 ,
}) {

  const { field: filed1, formState } = useController({ control, name: name1, defaultValue: "", rules: rules1 });
  const { field: filed2, formState: formState2 } = useController({ control, name: name2, defaultValue: "", rules: rules2 });
  const error1 = formState?.errors[name1]?.message
  const error2 = formState2?.errors[name2]?.message
  return (
    <Flex
      align="center"
      direction={{ base: "column", md: "column", lg: "row" }}
      justify="space-between"
      gap="2rem"
    >
      <FormControl
        isRequired={required}
        w={{ base: "100%", md: "100%", lg: "50%" }}
        isInvalid={error1}
      >
        <FormLabel
          h={{ base: "100%", md: "100%", lg: "20px" }}
          autoCapitalize="on"
          fontSize="14px"
          fontWeight="normal"
          color="#515262"
          style={{ textTransform: "uppercase" }}
        >
          {label1}
        </FormLabel>
        <Input
         {...filed1}
          size="md"
          variant="outline"
          border={border ? border : "1px solid #ccc"}
          focusBorderColor="#481053"
          borderRadius="14px"
          type={type ? type1 : "text"}
          placeholder={placeholder}

          value={value1}
          onChange={(e) => {
            filed1.onChange(e);
            onChange1(e);
          }}
        />
         <FormErrorMessage>{error1}</FormErrorMessage>
      </FormControl>
      <FormControl
        isRequired={required}
        w={{ base: "100%", md: "100%", lg: "50%" }}
        isInvalid={error2}
      >
        <FormLabel
          autoCapitalize="true"
          fontSize="14px"
          fontWeight="normal"
          h={{ base: "100%", md: "100%", lg: "20px" }}
          color="#515262"
          style={{ textTransform: "uppercase" }}
        >
          {label2}
        </FormLabel>
        <Input
          size="md"
          variant="outline"
          border="1px solid #ccc"
          focusBorderColor="#481053"
          borderRadius="14px"
          type={type ? type2 : "text"}
          maxLength={max ? max : ""}
          placeholder={placeholder2 ? placeholder2 : placeholder}
          value={value2}
          onChange={(e) => {
            filed2.onChange(e);
            onChange2(e);
          }}
          readOnly={readOnly ? readOnly : false}

        />
         <FormErrorMessage>{error2}</FormErrorMessage>
      </FormControl>
    </Flex>
  );
}
