import React from "react";
import UserDetail from "../directorate/user/user-detail/UserDetail";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { getUsers } from "../util/http";
import { useState } from "react";
import IdleWrapper from "../components/auth/IdleWrapper";

export default function UserDetailPage() {
  const params = useParams();
  const [progress, setProgress] = useState(0);
  const token = localStorage.getItem("token");

  const [user, setUser] = useState("");

  useEffect(() => {
    async function fetchData() {
      try {
        setProgress(20);
        setProgress(40);
        setProgress(60);
        const response = await getUsers(token, params.id);
        setProgress(80);
        setProgress(100);
        setUser(response?.user[0]);
      } catch (error) {
        setProgress(100);
        // console.log(error);
      }
    }
    fetchData();
  }, []);
  return (
    <IdleWrapper>
    <UserDetail progress={progress} setProgress={setProgress} user={user} />
    </IdleWrapper>
  );
}
