import React from "react";
import calender from "../../images/Calendar.png";
import {
  Flex,
  Spacer,
  Heading,
  Box,
  Menu,
  MenuButton,
  MenuList,
  Button,
  MenuItem,
} from "@chakra-ui/react";

import { ChevronDownIcon } from "@chakra-ui/icons";
import { getDashboard , getPastorDashboard } from "../../util/http";
import ReusableSubmissionHeader from "../ui/ReusableSubmissionHeader";

export default function SubmissionHeader({ setReport }) {
  const token = localStorage.getItem("token");
  const role = localStorage.getItem("role");
  async function filterPending() {
    let response;
    if (role.toLowerCase() === "directorate") {
      response = await getDashboard(token);
    } else if (role.toLowerCase() === "pastor") {
      response = await getPastorDashboard(token);
    }

    const filteredItem = response?.reports?.filter(
      (el) => el.status?.toLowerCase() === "pending"
    );
    setReport(filteredItem || []);
  }

  async function filterApproved() {
    let response;
    if (role.toLowerCase() === "directorate") {
      response = await getDashboard(token);
    } else if (role.toLowerCase() === "pastor") {
      response = await getPastorDashboard(token);
    }

    const filteredItem = response?.reports?.filter(
      (el) => el.status?.toLowerCase() === "approved"
    );
    setReport(filteredItem || []);
  }

  async function filterQueried() {
 let response;
    if (role.toLowerCase() === "directorate") {
      response = await getDashboard(token);
    } else if (role.toLowerCase() === "pastor") {
      response = await getPastorDashboard(token);
    }

    const filteredItem = response?.reports?.filter(
      (el) => el.status?.toLowerCase() === "queried"
    );

    setReport(filteredItem || []);
  }

  async function filterAll() {

    let response;
    if (role.toLowerCase() === "directorate") {
      response = await getDashboard(token);
    } else if (role.toLowerCase() === "pastor") {
      response = await getPastorDashboard(token);
    }
    setReport(response?.reports || []);
  }



  return (
    <ReusableSubmissionHeader
      filterApproved={filterApproved}
      filterPending={filterPending}
      filterQueried={filterQueried}
      filterAll={filterAll}
    />
  );
}
