import React, { Fragment, useState } from "react";
import AuthComponent from "../ui/AuthComponent";
import {
  Flex,
  Input,
  Button,
  FormControl,
  FormLabel,
  useToast,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import Loader from "../ui/Loader";
import { forgotPassword } from "../../util/http";

export default function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  const [progress, setProgress] = useState(0);

  const toast = useToast();

  function showToast(title, description, status) {
    toast({
      title: title,
      description: description,
      status,
      duration: 2000,
      isClosable: true,
      position: "top",
    });
  }

  const navigate = useNavigate();

  async function forgotHandler(e) {
    e.preventDefault();
    setLoading(true);
    const data = { email };

    try {
      setLoading(true);
      setProgress(20);
      setProgress(40);
      setProgress(60);
      const response = await forgotPassword(data);
      localStorage.setItem("user-email", email);
      localStorage.setItem("reset-token", response.token);
      navigate("/confirm-email");
      showToast("Password reset email successfully sent", "", "success");
      setProgress(80);
      setProgress(100);
      setLoading(false);
    } catch (error) {
      // console.log(error);
      showToast(
        error?.response?.data.message || "something went wrong",
        "",
        "error"
      );
      setLoading(false);
    }
  }

  return (
    <Fragment>
      <Loader progress={progress} setProgress={setProgress} />
      <AuthComponent
        heading="Reset Password"
        text="Don't have an Admin Account?"
        path="login"
        linkText="Sign In"
        blue={true}
      >
        <form
          onSubmit={forgotHandler}
          className="w-[90%] lg:w-[400px] mx-auto flex flex-col gap-4"
        >
          <FormControl isRequired>
            <FormLabel fontWeight="light" htmlFor="email">
              User Email
            </FormLabel>
            <Input
              size="md"
              focusBorderColor="#481053"
              borderRadius="14px"
              variant="outline"
              type="text"
              id="email"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </FormControl>
          <Flex w="100%" alignItems="center" justify="space-between">
            <Button
              w="100%"
              // colorScheme="purple"
              bg="brand.900"
              color="white"
              borderRadius="14px"
              variant="solid"
              isLoading={loading}
              loadingText="Checking"
              type="submit"
              isDisabled={!email || loading}
            >
              Get Reset Code
            </Button>
          </Flex>
        </form>
      </AuthComponent>
    </Fragment>
  );
}
