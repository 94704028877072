import React from "react";

import { getDashboard } from "../../util/http";
import ReusableReportFilter from "../ui/ReusableReportFilter";

export default function ReportFilter({ setReport }) {
  const token = localStorage.getItem("token");

  async function searchHandler(e) {
    const response = await getDashboard(token);
    const filteredItem = response.reports?.filter((el) =>
      el.service?.toLowerCase().includes(e.target.value.trim().toLowerCase())
    );

    setReport(filteredItem);
  }

  async function filterPending() {
    const response = await getDashboard(token);
    const filteredItem = response.reports?.filter(
      (el) => el.status?.toLowerCase() === "pending"
    );
    setReport(filteredItem);
  }

  async function filterQueried() {
    const response = await getDashboard(token);
    const filteredItem = response.reports?.filter(
      (el) => el.status?.toLowerCase() === "queried"
    );
    setReport(filteredItem);
  }

  async function filterApproved() {
    const response = await getDashboard(token);
    const filteredItem = response.reports?.filter(
      (el) => el.status?.toLowerCase() === "approved"
    );
    setReport(filteredItem);
  }

  async function filterAll() {
    const response = await getDashboard(token);
    setReport(response?.reports);
  }

  return (
    <ReusableReportFilter
      filterAll={filterAll}
      searchHandler={searchHandler}
      filterApproved={filterApproved}
      filterQueried={filterQueried}
      filterPending={filterPending}
    />
  );
}
