import React, { Fragment } from "react";
import Dashboard from "../components/dashboard/Dashboard";
import { useContext } from "react";
import { AuthContext } from "../util/authContext";
import LoginPage from "./LoginPage";
import IdleWrapper from "../components/auth/IdleWrapper";

export default function DashboardPage() {
  const ctx = useContext(AuthContext);

  return <Fragment>
    <IdleWrapper>
      {ctx.isLoggedIn ? <Dashboard /> : <LoginPage />
      }</IdleWrapper>
  </Fragment>;
}
