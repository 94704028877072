import React from "react";
import ReusableReportFilter from "../../components/ui/ReusableReportFilter";
import { adminReports , getPastorDashboard } from "../../util/http";

export default function DirectorateReportFilter({ setReport }) {
  const token = localStorage.getItem("token");
  const role = localStorage.getItem("role");
  


  async function searchHandler(e) {
      let response
    // const response = await adminReports(token);
    if (role.toLowerCase() === "directorate") {
      response = await adminReports(token);
    } else if (role.toLowerCase() === "pastor") {
      response = await getPastorDashboard(token);
    }
    const filteredItem = response.reports?.filter((el) =>
      el.service?.toLowerCase().includes(e.target.value.trim().toLowerCase())
    );

    setReport(filteredItem);
  }

  async function filterPending() {
    let response
    // const response = await adminReports(token);
    if (role.toLowerCase() === "directorate") {
      response = await adminReports(token);
    } else if (role.toLowerCase() === "pastor") {
      response = await getPastorDashboard(token);
    }
    const filteredItem = response.reports?.filter(
      (el) => el.status?.toLowerCase() === "pending"
    );
    setReport(filteredItem);
  }

  async function filterQueried() {

    let response
    // const response = await adminReports(token);
    if (role.toLowerCase() === "directorate") {
      response = await adminReports(token);
    } else if (role.toLowerCase() === "pastor") {
      response = await getPastorDashboard(token);
    }

    const filteredItem = response.reports?.filter(
      (el) => el.status?.toLowerCase() === "queried"
    );
    setReport(filteredItem);
  }



  async function filterApproved() {
    let response
    // const response = await adminReports(token);
    if (role.toLowerCase() === "directorate") {
      response = await adminReports(token);
    } else if (role.toLowerCase() === "pastor") {
      response = await getPastorDashboard(token);
    }
    const filteredItem = response.reports?.filter(
      (el) => el.status?.toLowerCase() === "approved"
    );
    setReport(filteredItem);
  }

  async function filterAll() {

    let response

    // const response = await adminReports(token);
    if (role.toLowerCase() === "directorate") {
      response = await adminReports(token);
    } else if (role.toLowerCase() === "pastor") {
      response = await getPastorDashboard(token);
    }

    const filteredItem = response.reports;
    setReport(filteredItem);
  }


  return (
    <ReusableReportFilter
      searchHandler={searchHandler}
      filterApproved={filterApproved}
      filterQueried={filterQueried}
      filterPending={filterPending}
      filterAll={filterAll}
    />
  );
}
