import React from 'react';
import { useIdleTimer } from 'react-idle-timer';

const IdleWrapper = ({ children }) => {


    const handleLogout = () => {

        // Clear storage
        localStorage.clear();
        window.location.href = '/login';

    };

    useIdleTimer({
        timeout: 20 * 60 * 1000,
        onIdle: handleLogout,
        debounce: 500,

    });

    return <div>{children}</div>;
};

export default IdleWrapper;
