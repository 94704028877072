import React from "react";
import { Textarea, FormLabel, Flex, FormControl , FormErrorMessage } from "@chakra-ui/react";
import ReusableReplyCommentBtn from "./ReusableReplyCommentBtn";
import { AddIcon } from "@chakra-ui/icons";
import { useController } from "react-hook-form";

export default function ReusableTextarea({
  value,
  onChange,
  label,
  register,
  visible,
  onClick,
  readOnly,
  bg,
  required = false,
  name ,
  control,
  defaultValue ,
  rules ,
}) {

  const { field ,  formState } = useController({
    control,
    name,
    defaultValue,
    rules,

  })
  const error = formState?.errors[name]?.message ;
  return (
    <FormControl isRequired={required} isInvalid={error} >
      <Flex w="100%" align="center" justify="space-between">
        <FormLabel
          style={{ textTransform: "uppercase" }}
          fontSize="14px"
          fontWeight="normal"
          h="20px"
          color="#515262"
        >
          {label}
        </FormLabel>
      </Flex>
      <Textarea
        {...field}
        size="md"
        variant="outline"
        border="1px solid #ccc"
        focusBorderColor="#481053"
        borderRadius="14px"
        placeholder="Enter Text Here"
        value={value}
        onChange={(e) => {
          field.onChange(e);
          if(onChange){
            onChange(e)
          }
        }}
        className="input"

        bg={bg ? "white" : "white"}
        readOnly={readOnly ? false : true}

      />
       <FormErrorMessage>{error}</FormErrorMessage>
    </FormControl>
  );
}
