import React, { Fragment } from "react";
import { Button, Flex, FormControl, FormLabel, Input, FormErrorMessage, Textarea } from "@chakra-ui/react";
import { Controller } from "react-hook-form";

export default function ReusableMixedField({
  label1,
  label2,
  placeholder,
  placeholder2,
  value1,
  onChange1,
  value2,
  onChange2,
  array,
  setArray,
  type,
  onBlur,
  required,
  control,
  name1,
  name2,
  rules1,
  rules2,
}) {
  function handleDelete(i) {
    const deleteVal = [...array];
    deleteVal.splice(i);
    setArray(deleteVal);
  }


  return (
    <Fragment>
      {array.map((item, i) => (
        <Flex
          w="100%"
          key={i}
          align="center"
          direction={{ base: "column", md: "column", lg: "row" }}
          justify="space-between"
          gap="2rem"
          position="relative"
        >
          <Controller
            control={control}
            name={name1 + i}
            rules={rules1}
            defaultValue={value1}
            render={({ field: filed1, formState }) => {
              const error1 = formState?.errors[name1 + i]?.message;


              return (
                <FormControl
                  w={{ base: "100%", md: "100%", lg: "48%" }}
                  height={{ base: "100%", md: "100%", lg: "100px" }}
                >
                  <FormLabel
                    fontSize="14px"
                    fontWeight="normal"
                    h={{ lg: "20px", base: "100%", md: "100%" }}
                    color="#515262"
                    style={{ textTransform: "uppercase" }}
                  >
                    {label1}
                  </FormLabel>
                  <Input
                    size="md"
                    variant="outline"
                    border="1px solid #ccc"
                    focusBorderColor="#481053"
                    borderRadius="14px"
                    type="text"
                    placeholder={placeholder}
                    value={value1}
                    onBlur={onBlur}
                    onChange={(e) => {
                      filed1.onChange(e);
                      onChange1(e, i);
                    }}
                  />
                  {
                    error1 ? (
                      <FormErrorMessage >
                        {error1}
                      </FormErrorMessage>
                    ) : null
                  }
                </FormControl>
              )

            }
            }
          />
          <Controller
            control={control}
            name={name2 + i}
            rules={rules2}
            defaultValue={value2}
            render={({ field: filed2, formState: formState2 }) => {
              const error2 = formState2?.errors[name2 + i]?.message;
              console.log(error2);
              return (
                <FormControl
                  w={{ base: "100%", md: "100%", lg: "48%" }}
                  height={{ base: "100%", md: "100%", lg: "100px" }}
                >
                  <FormLabel
                    fontSize="14px"
                    fontWeight="normal"
                    h="20px"
                    color="#515262"
                    style={{ textTransform: "uppercase" }}
                  >
                    {label2}
                  </FormLabel>
                  <Textarea
                    size="md"
                    variant="outline"
                    border="1px solid #ccc"
                    focusBorderColor="#481053"
                    borderRadius="14px"
                    type={type ? type : "text"}
                    placeholder={placeholder2 ? placeholder2 : placeholder}
                    value={value2}
                    onBlur={onBlur}
                    onChange={(e) => {
                      filed2.onChange(e);
                      onChange2(e, i);
                    }}


                  />
                  <FormErrorMessage >
                    {error2}
                  </FormErrorMessage>
                </FormControl>
              )
            }
            }
          />
          {array.length > 1 && (
            <Button
              onClick={() => {
                array.length > 1 && i !== 0 && handleDelete(i);
              }}
              position="absolute"
              top="0"
              colorScheme="red"
              variant="ghost"
              right="0"
            >
              X
            </Button>
          )}
        </Flex>
      ))}
    </Fragment>
  );
}
